<template>
    <div>
		<template v-if="reporteInventario.tipo === 'reporte-inventario'">
			<ReporteInventario
				:reporteInventario="reporteInventario"
			/>
		</template>

		<template v-if="reporteInventario.tipo === 'reporte-proveedor'">
			<ReporteProveedor
				:reporteInventario="reporteInventario"
			/>
		</template>

		<template v-if="reporteInventario.tipo === 'reporte-producto'">
			<ReporteProducto
				:reporteInventario="reporteInventario"
			/>
		</template>

		<template v-if="reporteInventario.tipo === 'reporte-categoria'">
			<ReporteCategoria
				:reporteInventario="reporteInventario"
			/>
		</template>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.9); z-index: 1020;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as utils from '@/utils/utils'
  import router from '@/config/router'
  import ReporteInventario from './Modulo/ReporteInventario.vue'
  import ReporteProveedor from './Modulo/ReporteProveedor.vue'
  import ReporteProducto from './Modulo/ReporteProducto.vue'
  import ReporteCategoria from './Modulo/ReporteCategoria.vue'

  export default {

	  components: {
	      ReporteInventario,
		  ReporteProveedor,
		  ReporteProducto,
		  ReporteCategoria
	  },

      data () {
          return {
			 utils,
			 spinnerloading: false
          }
      }, 

      computed: {
          ...mapGetters('auth', ['user']),
          ...mapGetters('estadistica', ['reporteInventario'])
      },

      methods: {
		  ...mapActions('estadistica', ['descargarReporteInventario']),

		  descargarInventario () {
			  this.spinnerloading = true
			  const consulta = this.$route.query
              this.descargarReporteInventario(consulta)
			  .then(response => {
				 this.spinnerloading = false
			  })
			  .catch(error => {
				   this.spinnerloading = false
				   alert(utils.showErrors(error.response.data))
				   window.close()
				   utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
			  })	 
 		  }
      },

	  mounted () {
		  this.descargarInventario()
	  }
  }
</script>